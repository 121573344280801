<template>
  <div class="max-w-xl mx-auto">
    <div 
      v-for="(blob, index) in blobs" :key="'blob-'+index"
      class="px-6 py-3 relative"
    >
      <img 
        :src="blob.imgSource"
        alt="Blob image captured"
        class="rounded-xl"
      >
      <button
        v-if="global.modeBZR"
        @click="replace(index)" type="button" 
        class="absolute right-2 bottom-8 z-10 p-2 bg-bzr-lightgrey hover:bg-bzr-primary/90 rounded-full flex flex-row justify-start items-center cursor-pointer transition-colors ease-in-out group"
      >
        <TrashIcon class="w-6 h-6 text-bzr-grey group-hover:text-white transition-colors ease-in-out"/>
      </button>
      <button
        v-else
        @click="replace(index)" type="button" 
        class="h-min absolute right-2 bottom-8 z-10 p-2 text-gray-400 bg-gray-700 hover:bg-gray-600 rounded-full transition ease-in-out" 
      >
        <TrashIcon class="w-6 h-6"/>
      </button>
    </div>
  </div>
  <div 
    v-if="blobs.length < imagesAmount"
    class="relative bg-dark rounded-2xl"
  >
    <Camera 
      :resolution="{ width: 2560, height: 1920 }" 
      ref="camera" 
      autoplay
    />
    <div 
      style="border-width: 36px 30px;"
      class="w-full h-full absolute inset-0 z-10 border-black border-opacity-40 rounded-2xl"
    >
      <div class="absolute bg-white rounded w-10 h-1 -top-1 -left-1"></div>
      <div class="absolute bg-white rounded w-10 h-1 -top-1 -right-1"></div>
      <div class="absolute bg-white rounded w-10 h-1 -bottom-1 -left-1"></div>
      <div class="absolute bg-white rounded w-10 h-1 -bottom-1 -right-1"></div>
      <div class="absolute bg-white rounded w-1 h-10 -top-1 -left-1"></div>
      <div class="absolute bg-white rounded w-1 h-10 -top-1 -right-1"></div>
      <div class="absolute bg-white rounded w-1 h-10 -bottom-1 -left-1"></div>
      <div class="absolute bg-white rounded w-1 h-10 -bottom-1 -right-1"></div>
    </div>
    <div v-if="global.modeBZR" class="w-full absolute -bottom-6 z-20 flex flex-row justify-center">
      <button 
        @click="snapshot" type="button" 
        class="w-16 h-16 text-bzr-primary bg-white border-[10px] border-bzr-primary hover:text-white hover:bg-bzr-primary hover:border-white rounded-full transition ease-in-out" 
      >
        <CameraIcon class="w-8 relative left-[7px]"/>
      </button>
    </div>
    <div v-else class="w-full absolute -bottom-6 z-20 flex flex-row justify-center">
      <button 
        @click="snapshot" type="button" 
        class="w-16 h-16 text-dark bg-primary border-[12px] border-dark hover:border-secondary rounded-full transition ease-in-out" 
      >
        <CameraIcon class="w-8 relative left-[4px]"/>
      </button>
    </div>
  </div>
  <CamPicker 
    v-if="blobs.length < imagesAmount" 
    :cameras="camList"
    :deviceId="deviceId"
    :back_cameras="back_cameras"
    :front_cameras="front_cameras"
    :generic_cameras="generic_cameras"
    @change="changeCamera"
  />
</template>

<script>
import globalStore from '@/store.js'
import Camera from 'simple-vue-camera'
import CamPicker from '@/components/CamPicker.vue'
import { CameraIcon, TrashIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    Camera,
    CamPicker,
    CameraIcon,
    TrashIcon
  },
  emits: ['captured', 'capturedAll'],
  props: {
    imagesAmount: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      global: globalStore(),
      blobs: [],
      simpleCam: null,

      camList: [],
      deviceId: null,

      generic_cameras: 0,
      front_cameras: 0,
      back_cameras: 0,
    }
  },
  beforeUnmount() {
    this.blobs = []
    this.simpleCam = null
  },
  methods: {
    async snapshot() {
      this.simpleCam = this.$refs['camera']
      const cam = document.getElementById('camera-container')

      let resolution = {
        width: 2560,
        height: 1920
      }
      // console.log(this.simpleCam?.canvas?.width, this.simpleCam?.canvas?.height);
      if (cam?.clientWidth && cam?.clientHeight && 
          cam?.clientWidth <= cam?.clientHeight) {
        resolution = {
          width: 1920,
          height: 2560
        }
      }

      const blob = await this.simpleCam.snapshot(
        resolution, // ?necessary? 4/3 aspect-ratio
        "image/jpeg",
        0.95
      )
      const blobImage = {
        raw: blob, 
        imgSource: URL.createObjectURL(blob)
      }
      // console.log(blobImage)

      this.blobs.push(blobImage)
      this.$emit('captured', this.blobs)
      if (this.blobs.length == this.imagesAmount)
        this.$emit('capturedAll', this.blobs)
    },

    replace(index) {
      this.blobs.splice(index, 1)
      if (this.blobs.length === 0) {
        this.$emit('capturedAll', null)
        return
      }
      this.$emit('capturedAll', this.blobs)
    },

    changeCamera(deviceId) {
      this.deviceId = deviceId
      this.simpleCam.changeCamera(this.deviceId)
    },

    labelCamera(cam) {
      let cout = {}  
      if (cam.label.toLowerCase().includes('front')) {
        cout.front_cam = 'Přední kamera'
        cout.front_index = this.front_cameras
        this.front_cameras++
      }
      else if (cam.label.toLowerCase().includes('back')) {
        cout.back_cam = 'Zadní kamera'
        cout.back_index = this.back_cameras
        this.back_cameras++
      }
      else {
        cout.generic_cam = 'Kamera'
        cout.generic_index = this.generic_cameras
        this.generic_cameras++
      }
      return cout
    }
  },
  async mounted() {
    this.simpleCam = this.$refs['camera']

    this.camList = []
    let cameras = await this.simpleCam.devices(["videoinput"])
    for (let i = 0; i < cameras.length; i++) {
      if (this.camList.findIndex(cam => cam.device.deviceId == cameras[i]?.device?.deviceId) == -1) {
        this.camList.push({
          device: cameras[i],
          label: this.labelCamera(cameras[i])
        })
      }
    }
  }
}
</script>

<style>
#camera-container,
#camera-container video {
  /* width: 493px !important; */
  @apply relative rounded-2xl;
}
</style>
