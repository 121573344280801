import axios from 'axios'
import qs from 'qs'


axios.interceptors.response.use(
    (res) => res,
    function (err) {
        if (typeof err.response === 'undefined') {
            if (typeof err.message !== 'undefined') {
                console.warn(err.message)
            } else {
                console.log({err})
                console.error('Connection with server lost')
            }
        } else {
            if (
                [401, 403].includes(err.response.status) &&
                err.response.config &&
                !err.response.config.__isRetryRequest
            ) {
                console.log('Interceptor logged you out', err.response)
                throw err
            } else {
                return Promise.reject(err)
            }
        }
    },
)

const _query = (params) => {
    const q = qs.stringify(params, {encodeValuesOnly: true})
    return q === '' ? '' : `?${q}`
}


// User handlers

export const login = (email, token) => {
    //const query = _query({})

    // POPULATE JE ZDE HARD-CODED NA BACKENDU
    return axios.post('/auth/local', {
        identifier: email,
        one_time_token: token,
        password: 'xown'
    }, {
        headers: {authorization: null}
    }).then(res => {
        return res?.data
    }).catch(err => {
        throw err
    })
}

export const updateUser = (id, data, populate = undefined, action = undefined) => {
    const query = _query({populate, action})
    return axios.put(`/users/${id}${query}`, data).then(res => res?.data)
}


// File handlers

export const filePath = (url) => {
    return import.meta.env.VITE_STORAGE_URL + url;
    // return axios.defaults.baseURL.replace('/api', '') + url
}

export const fileUpload = (data) => {
    return axios.post('/secure-upload', data).then(res => res?.data)
}

export const getFile = (id, populate = undefined) => {
    const query = _query({populate})
    return axios.get(`/upload/files/${id}${query}`).then(res => res?.data)
}

export const fileDelete = (id) => {
    return axios.delete(`/upload/files/${id}`).then(res => res?.data)
}


// Generic REST methods

export const get = (path, filters = undefined, populate = undefined, pagination = undefined, sort=undefined) => {
    const query = _query({filters, populate, pagination, sort})
    return axios.get(`/${path}${query}`).then(res => res?.data).catch(e => e.response)
}

export const getOne = (path, id, filters = undefined, populate = undefined) => {
    const query = _query({filters, populate})
    return axios.get(`/${path}/${id}${query}`).then(res => res?.data)
}

export const create = (path, data = undefined, populate = undefined) => {
    const query = _query({populate})
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    return axios.post(`/${path}${query}`, {data}).then(res => res?.data)
}

export const update = (path, id, data = undefined) => {
    return axios.put(`/${path}/${id}`, {data}).then(res => res?.data)
}

export const remove = (path, id) => {
    return axios.delete(`/${path}/${id}`).then(res => res?.data)
}
